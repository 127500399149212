import React from "react";

import Layout from "../layout/base";
import SEO from "../components/seo";
import Breadcrumb from "../components/breadcrumb";
import Stage from "../components/stage";
import JSON from "../../data/privacy.json";
import Accordion from "../components/accordion";

const IndexPage = ({ i18n = "en" }) => (
  <Layout site="privacy" i18n={i18n}  url="privacy">
    <SEO
      title={JSON[i18n].meta.title}
      description={JSON[i18n].meta.description}
      lang={i18n}
    />
    <Stage className="mb-5 stage--large" site="privacy" i18n={i18n}>
      <div className="stage__title -isPrivacy">
        <Breadcrumb data={JSON[i18n].breadcrumb} i18n={i18n} />
        <h1>{JSON[i18n].stage.title}</h1>
        <p>{JSON[i18n].stage.subtitle}</p>
      </div>
    </Stage>

    <div className="container d-lg-none mb-5">
      <div className="row">
        <div className="col-12 util-color-primary text-center">
          <p className="util-h1 util-h1--xs font-weight-bold util-color-primary">
            {JSON[i18n].stage.title}
          </p>
          <p className="util-text-1 util-text-1--xs">
            {JSON[i18n].stage.subtitle}
          </p>
        </div>
      </div>
    </div>

    <div className="container util-content-pt-1 util-content-pb-1">
      <div className="row ">
        <div className="col-12 col-lg-6">
          <h2 className="util-h1 util-color-primary">
            {JSON[i18n].content1.title}
          </h2>
          <div
            className="util-text-1"
            dangerouslySetInnerHTML={{ __html: JSON[i18n].content1.text1 }}
          ></div>
        </div>
        <div
          className="col-12 col-lg-6 util-text-1"
          dangerouslySetInnerHTML={{ __html: JSON[i18n].content1.text2 }}
        ></div>
      </div>
    </div>

    <div className="container mb-5 ">
      <div className="row mb-5">
        <div className="col-12">
          <Accordion
            className="accordion accordion--large accordion--primary"
            allowMultipleOpen={true}
          >
            {JSON[i18n].accordion.map((section, i) => {
              return (
                <div
                  key={i}
                  label={section.title}
                  isOpen={section.open}
                  id={section.id}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: section.content }}
                  ></div>
                </div>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>

    <div className="container util-content-pb-2">
      <div className="row ">
        <div className="col-12 col-lg-6">
          <h2 className="util-h1 util-color-primary">
            {JSON[i18n].content2.title}
          </h2>
          <div
            className="util-text-1"
            dangerouslySetInnerHTML={{ __html: JSON[i18n].content2.text1 }}
          ></div>
        </div>
        <div
          className="col-12 col-lg-6 util-text-1"
          dangerouslySetInnerHTML={{ __html: JSON[i18n].content2.text2 }}
        ></div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
